import React, { useState } from 'react'
import { Col, Container, Modal, Row } from 'react-bootstrap';
import config from "../../config";
import Footer from "../section/footer";
import MFAOption from "./mfa"
import { Login } from "@slbnsc/react-portal";
import RegisterForm from "../section/register";
import ForgotPassword from "../forgot-password/index";
import Terms from "../portal/terms";
import PrivacyPolicy from "../portal/privacy-policy";

const  LoginPage = (props) =>{
  const [register, setRegister] = useState(false);
  const [fullHeight, setFullHeight] = useState(true);
  const [showTerm, setTerm] = useState(false);
  const [showPolicy, setPolicy] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [mfa, setMfa] = useState(null);
  const dateNow = new Date();
  const year = dateNow && dateNow.getFullYear();
  const handleClickForgotPassword = async (e) => {
    e.preventDefault();
    return setShowForgotPassword(true);
  }

  const onCloseForgotPassword = async () => {
    return setShowForgotPassword(false);
  }

  return (
  <>
    {showTerm && <Terms onClose={() => setTerm(false)} />}
    {showPolicy && <PrivacyPolicy onClose={() => setPolicy(false)} />}
    <Container fluid className={`fh no-gutters login-main-body login-page ${fullHeight ? "vh-page" : ""} `}>
      <Row className="login-main-header">
        <header className="login-main-logo">
          <a href="https://shalomcollege.unsw.edu.au/" target="_blank" className="image-brand-link">
            <img src="https://cdn.vostro.app/cdn/hotspots/shalom/Shalom-College-Primary-logo white.png" className="image-brand-logo" alt="brand-logo"/>
          </a>
        </header>
      </Row>
      <Row className="fh-element align-items-center">
        <Col>
          {showForgotPassword && <ForgotPassword onClose={onCloseForgotPassword} />}
          {register &&
            <Container className="mb-5">
              <Container>
                <RegisterForm setFullHeight={async (data) => setFullHeight(data)} backToLogin={async () => setRegister(false)} />
              </Container>
            </Container>
          }
          {!register && <Container>
            <Row className="login-main-row">
              <Col lg={7} xl={7} className="mb-lg-0 login-brand-container">
               
                <div className="login-brand-secondary-section">
                  <img 
                    alt="login-brand-image" 
                    className="login-brand-image"
                    src="https://cdn.vostro.app/cdn/hotspots/shalom/shalom college school slogan.png"
                  />
                </div>
              </Col>
              <Modal
                show={mfa}
                className="login-mfa-panel-modal"
                onHide={()=> {}}
              >
                <MFAOption mfa={mfa} setMfa={setMfa} authUrl={config.auth} portalUrl={config.portal} apiUrl={config.backend}/>
              </Modal>
              {!mfa && ( <Col lg={5} xl={5} className="mb-lg-0 login-message-container">
                <div className="login-section-container">
                  <div className="login-container">
                    <p className="login-title">
                      {"Login to your account"}
                    </p>
                  
                    <Login
                      inputClassName={"login-form-input"}
                      noLabels={false}
                      labelClassName={"form-input-label"}
                      authUrl={config.auth} 
                      portalUrl={config.portal} 
                      apiUrl={config.backend}
                      noPlaceHolders={true}
                      floating={true}
                      inline={true}
                      withErrorOnButton={true}
                      withUserIcon={true}
                      loginBtnLabel={"Login"}
                      withPasswordIcon={true}
                      withRecoverPasswordLink={true}
                      setMfa={setMfa} 
                      recoverPasswordLink={<>
                        <a className="forgot-password" onClick={handleClickForgotPassword}>{"Recover Password"}</a>
                      </>}
                    />
                  </div>
                </div>
              </Col>)}
            </Row>
          </Container>}
        </Col>
      </Row>
      <Row>
        <Col>
          <Footer year={year} setTerm={setTerm}/>
        </Col>
      </Row>
    </Container>
  </>
  );
}


export default LoginPage;

